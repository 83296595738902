import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';

import { Component } from './component';
import { useQuery } from 'react-query';
import { action as fetch } from './action';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { convertSession } from '../../data/session/convert';
import { appLoadLocalStateData } from '../../data/app';
import { useHistory } from 'react-router';
import { SESSION_ITEM_PAGE_PATH_DYNAMIC } from '../../page/session-item-data';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { setSessionToken } from '../../lib/jwt/service';
import { HttpRequest } from '../../lib/http';
import { MESSAGE_ITEM_DATA } from '../../data/message/constant';
import {
  EVENT_ITEM_DATA_RAW,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
  SURVEY_TYPE,
} from '../../data/event/constant';
import { SESSION_ITEM_OVER_PAGE_PATH_DYNAMIC } from '../../page/session-item-over';
import useRoomSocket from './useSocket';
import { bannedViewerOnLocal } from '../../data/auth/action';
import { useDispatch } from '../../lib/store';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';
import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const [sessionToken, setSessionTokenState] = useState(
    HttpRequest.defaults.headers.common['session_token'] as string,
  );
  const [chatMessage, setChatMessage] = useState<MESSAGE_ITEM_DATA[]>([]);
  const [sessionStart, setSessionStart] = useState<boolean>(false);
  const [eventStarted, setEventStarted] = useState<boolean>(false);
  const [chatActive, setChatActive] = useState<boolean>(false);
  const [sessionEnd, setSessionEnd] = useState<boolean>(false);
  const [event, setEvent] = useState<EVENT_ITEM_DATA_RAW | undefined>(
    undefined,
  );
  const [button, setButton] = useState<EVENT_ITEM_DATA_RAW | undefined>(
    undefined,
  );
  const [banner, setBanner] = useState<EVENT_ITEM_DATA_RAW | undefined>(
    undefined,
  );
  const [reactions, setReactions] = useState<REACTION_RAW_TYPE | undefined>(
    undefined,
  );

  const [question, setQuestion] = useState<QUESTION_TYPE | undefined>(
    undefined,
  );

  const [survey, setSurvey] = useState<SURVEY_TYPE | undefined>(undefined);

  const [reservationCreated, setReservationCreated] = useState<
    RESERVATION_CREATED_TYPE | undefined
  >(undefined);

  const preFetchViewer = useQuery([VIEWER_ITEM_DATA_MODULE_NAME], {
    enabled: !!HttpRequest.defaults.headers.common['session_token'],
  });

  useEffect(() => {
    const data = preFetchViewer.data as unknown as VIEWER_ITEM_DATA;
    if (data?.isBanned) {
      history.push(SESSION_ITEM_PAGE_PATH_DYNAMIC(sessionId));
    }
  }, []);

  const onSuccess = (d: any) => {};

  const history = useHistory();

  const action = useQuery(MODULE_NAME, () => fetch(sessionId), { onSuccess });
  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = action.data as unknown as SESSION_ITEM_DATA;

    if (data) {
      return convertSession(data);
    }
  };
  const data = useMemo(() => getData(), [action.data]);

  const localData = appLoadLocalStateData(AUTH_MODULE_NAME);

  useEffect(() => {
    if (data?.sessionStarted) setSessionStart(true);

    if (data?.sessionIsOver) setSessionEnd(true);

    if (data?.eventStarted) setEventStarted(true);
    if (data?.chatActive) setChatActive(true);
  }, [data]);

  useEffect(() => {
    if (sessionEnd) {
      history.push(SESSION_ITEM_OVER_PAGE_PATH_DYNAMIC(sessionId));
    }
  }, [sessionEnd]);

  useEffect(() => {
    appLoadLocalStateData(AUTH_MODULE_NAME).then((localData) => {
      if (!localData) {
        history.push(SESSION_ITEM_PAGE_PATH_DYNAMIC(sessionId));
      } else {
        if (
          !localData?.sessionToken ||
          !localData?.sessionToken[sessionId] ||
          localData.isBanned
        ) {
          history.push(SESSION_ITEM_PAGE_PATH_DYNAMIC(sessionId));
        } else {
          if (
            localData?.sessionToken[sessionId] !==
            HttpRequest.defaults.headers.common['session_token']
          ) {
            setSessionToken(localData?.sessionToken[sessionId]);
            setSessionTokenState(localData?.sessionToken[sessionId]);
          }
        }
      }
    });
  }, [localData]);

  const dispatch = useDispatch();

  const viewerBan = () => {
    dispatch(bannedViewerOnLocal()).then(() => {
      history.push(SESSION_ITEM_PAGE_PATH_DYNAMIC(sessionId));
    });
  };

  const { sendMessage, reservationButtonClick, sendReaction, sendAnswer } =
    useRoomSocket({
      sessionToken,
      sessionId,
      setChatMessage,
      setSessionStart,
      setEventStarted,
      setSessionEnd,
      setEvent,
      setBanner,
      setButton,
      setReactions,
      setReservationCreated,
      setQuestion,
      setChatActive,
      setSurvey,
      viewerBan,
    });
  return (
    <Component
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      data={data}
      sessionId={sessionId}
      sessionToken={sessionToken}
      chatMessage={chatMessage}
      sendMessage={sendMessage}
      event={event}
      setChatMessage={setChatMessage}
      reservationButtonClick={reservationButtonClick}
      sessionStart={sessionStart}
      eventStarted={eventStarted}
      sessionEnd={sessionEnd}
      button={button}
      banner={banner}
      reaction={reactions}
      sendReaction={sendReaction}
      reservationCreated={reservationCreated}
      question={question}
      chatActive={chatActive}
      survey={survey}
      sendAnswer={sendAnswer}
    />
  );
};
