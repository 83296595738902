import React from 'react';
import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { getPersonList } from '../../../data/person/action';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { SelectDynamicContainer } from '../../select-dynamic';
import { FieldTextElem } from '../../../common/field-text';

export const QuestionForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  sessionId: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  sessionId,
}) => {
  console.log(formik.values);
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          onBlur={formik.setFieldTouched}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.END_TIME}
          onChange={formik.handleChange}
          onBlur={formik.setFieldTouched}
          title="EVENT.FORM.END_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
          error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
        />
      </DoubleContainerElem>
      <SelectDynamicContainer
        name={FORM_VALUE_ENUM.PERSON}
        action={getPersonList}
        convert={convertPersonListToSelect}
        formik={formik}
        title="EVENT.FORM.QUESTION_AUTHOR"
        onChange={setFieldValue}
        errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON)}
        error={isFieldError(FORM_VALUE_ENUM.PERSON)}
        value={getFieldValue(FORM_VALUE_ENUM.PERSON)}
        param={sessionId}
      />
      <FieldTextElem
        name={FORM_VALUE_ENUM.MESSAGE}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.QUESTION"
        value={getFieldValue(FORM_VALUE_ENUM.MESSAGE)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.MESSAGE)}
        error={isFieldError(FORM_VALUE_ENUM.MESSAGE)}
      />
    </GridElem>
  );
};
