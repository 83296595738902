import React, { useRef, useState } from 'react';

import { GridElem } from '../../common/grid';

import styled from 'styled-components';
import { SortSelect } from './frame/sort-select';
import { ButtonElem } from '../../common/button';
import { useHistory } from 'react-router';
import { WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC } from '../../page/webinar-item-import';
import { PersonSelect } from './frame/person-select';
import { TypeSelect } from './frame/type-select';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { SESSION_DATA_MODULE_NAME } from '../session-data';
import { SESSION_ITEM_IMPORT_PAGE_PATH_DYNAMIC } from '../../page/session-item-import';
import { DividerElem } from '../../common/divider';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { useOnClickOutside } from 'usehooks-ts';
import Icon from '../../asset/svg/common/horisontalDots.svg';
import { exportToExcel, importFromExcel } from './action';
import { EVENT_LIST_MODULE_NAME } from '../event-list';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { ACTION_ERROR_INTER } from '../webinar-list/constant';

export const Component: React.FC<{
  setSort: Function;
  sort: string;
  type: string;
  setType: Function;
  setPerson: Function;
  person: string;
  sessionId: string;
}> = ({ sort, setSort, person, setPerson, type, setType, sessionId }) => {
  const history = useHistory();
  const query = useQueryClient();

  const preFetch = useQuery(SESSION_DATA_MODULE_NAME);

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const data = getData();

  const [modalVisible, setModalVisible] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setModalVisible(false);
  };

  const handleImportWebinar = () => {
    history.push(
      WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC(
        data?.webinar.id,
        `/?event=true&sessionId=${sessionId}`,
      ),
    );
  };

  const handleImportSession = () => {
    history.push(
      SESSION_ITEM_IMPORT_PAGE_PATH_DYNAMIC(
        data?.webinar.id,
        sessionId,
        '/?event=true',
      ),
    );
  };

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  useOnClickOutside(ref, handleClickOutside);

  const {
    mutate: exportToExcelAction,
    isLoading: isLoadingExport,
    error: errorExport,
    isError: isErrorExport,
  } = useMutation(() => exportToExcel(sessionId), {
    onSuccess: (blob) => {
      console.log(blob);
      //@ts-ignore
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'session-data.xlsx'); // Имя файла
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    },
  });

  const exportToExcelHandle = () => {
    setModalVisible((state) => !state);

    exportToExcelAction();
  };

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // useMutation для отправки файла
  const {
    mutate: uploadFileMutation,
    isLoading: isLoadingUpload,
    error: uploadError,
    isError: isErrorUpload,
    isSuccess,
  } = useMutation((file: File) => importFromExcel(sessionId, file), {
    onSuccess: () => {
      query.invalidateQueries([EVENT_LIST_MODULE_NAME, sessionId]);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file); // Trigger the upload
    }
    setModalVisible(false);
  };

  const handleFileUpload = (file: File) => {
    if (file) {
      uploadFileMutation(file);
    }
  };

  const isError = () => {
    if (
      (isErrorExport || isErrorUpload) &&
      (!isLoadingUpload || isLoadingExport) &&
      getErrorMessage()
    ) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = (uploadError ||
      errorExport) as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const isLoading = () => {
    if (isLoadingUpload || isLoadingExport) {
      return true;
    }
  };
  console.log(modalVisible);
  return (
    <Container spacing={4}>
      {isLoading() && <LoaderElem />}
      {isError() && <AlertActionElem text={getErrorMessage()} />}

      <SortSelect sort={sort} setSort={setSort} />

      <PersonSelect
        person={person}
        setPerson={setPerson}
        sessionId={sessionId}
      />
      <TypeSelect type={type} setType={setType} />
      <ModalContainer onClick={handleOpenModal}>
        <ButtonElemStyled
          color="backgroundThird"
          tid="PERSON.FILTER.IMPORT.BUTTON"
          iconRight={Icon}
        />
        <ModalElem ref={ref} visible={modalVisible}>
          <ModalItem onClick={exportToExcelHandle}>
            <TextElem tid="EVENT.FILTER.EXPORT_EXCEL" />
          </ModalItem>
          <ModalItem style={{ position: 'relative' }}>
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileChange}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                background: 'transparent',
                opacity: '0',
                left: '0',
                cursor: 'pointer',
              }}
            />
            <TextElem tid="EVENT.FILTER.IMPORT_EXCEL" />
          </ModalItem>
          <DividerElem />

          <ModalItem onClick={handleImportWebinar}>
            <TextElem tid="EVENT.FILTER.IMPORT" />
          </ModalItem>
          <DividerElem />

          <ModalItem onClick={handleImportSession}>
            <TextElem tid="PERSON.FILTER.IMPORT.SESSION" />
          </ModalItem>
        </ModalElem>
      </ModalContainer>
    </Container>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  > div {
    align-items: center;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  height: 46px;
  display: flex;
  cursor: pointer;
`;

const ModalItem = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  align-items: center;
  height: 48px;
  border-radius: 0;
  span {
    height: 16px;
  }

  :first-child {
    border-radius: 9px 9px 0 0;
  }

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  }
`;
const ModalElem = styled.div<{ visible: boolean }>`
  z-index: 999;
  background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  top: 54px;
  right: 0;
  width: 180px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  position: absolute;
  display: ${({ visible }) => (visible ? 'block' : 'none')};

  border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  width: 180px;
  --height: auto;
  --box-shadow: none !important;
  --backdrop-opacity: 0;

  div:first-child {
    border-radius: 9px 9px 0 0;
  }

  div:last-child {
    border-radius: 0 0 9px 9px;
  }
`;

const Container = styled(GridElem)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (width<800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (width<630px) {
    grid-template-columns: 1fr;
  }
  .button {
    max-width: 182px;

    @media screen and (width<630px) {
      max-width: 100%;
    }
  }

  align-content: start;
`;
