import {
  VIEWER_ITEM_LIST_DATA_RAW,
  VIEWER_ITEM_LIST_DATA,
} from '../../data/viewer/constant';

export const MODULE_NAME = 'ANALYTIC_ITEM_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  PROJECT = 'project',
  WEBINAR = 'webinar',
  SESSION = 'session',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.PROJECT]: string | string[];
  [FORM_VALUE_ENUM.WEBINAR]: string | string[];
  [FORM_VALUE_ENUM.SESSION]: string | string[];
}

export const API = {
  TYPE: 'GET',
  URL: (viewerId: string) => `/viewer/${viewerId}`,
};

export interface ACTION_RESPONSE_INTER extends VIEWER_ITEM_LIST_DATA_RAW {}

export interface DATA extends VIEWER_ITEM_LIST_DATA {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
