//@ts-nocheck

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { SkeletonFieldElem } from '../../common/skeleton-field';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import axios from 'axios';
import { BASE_URL } from '../../lib/http/constant';
import ReactPlayer from 'react-player/youtube';

export const Component: React.FC<{
  src?: string;
}> = ({ src }) => {
  const playerRef = useRef(null);

  const handleReady = () => {
    // YouTube player instance
    const internalPlayer = playerRef.current.getInternalPlayer();
    if (internalPlayer && typeof internalPlayer.playVideo === 'function') {
      console.log(internalPlayer);
      internalPlayer.playVideo();

      internalPlayer.setVolume(50);
      // internalPlayer.unMute();
    }
  };

  const handlePlay = () => {
    console.log('Video is playing');
  };
  return (
    <PlayerWrapper>
      <Player
        url={src}
        playing={true}
        controls
        ref={playerRef}
        onReady={handleReady}
        onPlay={handlePlay}
        muted={true}
        style={{ width: '100% !important', height: '100%  !important' }}
      />
    </PlayerWrapper>
  );
};

const Player = styled(ReactPlayer)`
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  video {
    width: 100% !important;
    height: 100% !important;
  }
`;

const PlayerWrapper = styled.div`
  /* position: relative; */
  /* padding-top: 56.25%; */
`;
