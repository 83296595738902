import React, { useEffect, useState } from 'react';

import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { FlexElem } from '../../common/flex';
import logoIcon from '../../asset/svg/logo.svg';
import logoIconLight from '../../asset/svg/logo-light.svg';
import { THEME_MODULE_NAME } from '../../data/theme';
import { useSelector } from '../../lib/store';
import { THEME_ENUM } from '../../data/theme/constant';
import { SessionItemRoomReservationContainer } from '../session-item-room-reservation';
import { Skeleton } from '../session-item-room-reservation/frame/skeleton';
import { ContentContainerElem } from '../../common/content-container';
import { UTMObject } from '../../data/viewer/constant';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  sessionId: string;
  query: UTMObject;
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  query,
  sessionId,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));
  return (
    <Container spacing={7}>
      <Logo src={theme.type === THEME_ENUM.LIGHT ? logoIconLight : logoIcon} />

      {isLoading && (
        <ContentContainerElem style={{ width: '472px', margin: 'auto' }}>
          <Skeleton />
        </ContentContainerElem>
      )}
      {isSuccess && data && (
        <SessionItemRoomReservationContainer
          sessionId={sessionId}
          webinarData={data.webinar}
          query={query}
        />
      )}
    </Container>
  );
};

const Container = styled(GridElem)`
  /* justify-content: center; */
`;

const Logo = styled.img`
  margin: auto;
  height: 28px;
`;
