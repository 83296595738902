import React, { useRef, useState } from 'react';

import { GridElem } from '../../common/grid';
import { Search } from './frame/search';
import Icon from '../../asset/svg/common/horisontalDots.svg';

import styled from 'styled-components';
import { SortSelect } from './frame/sort-select';
import { ButtonElem } from '../../common/button';
import { useHistory } from 'react-router';
import { WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC } from '../../page/webinar-item-import';
import { useOnClickOutside } from 'usehooks-ts';
import { DividerElem } from '../../common/divider';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { SESSION_ITEM_IMPORT_PAGE_PATH_DYNAMIC } from '../../page/session-item-import';
import { useQuery } from 'react-query';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { SESSION_DATA_MODULE_NAME } from '../session-data';

export const Component: React.FC<{
  setSort: Function;
  sort: string;
  search: string;
  setSearch: Function;
  // setSession: Function;
  // session: string;
  sessionId: string;
}> = ({ sort, setSort, search, setSearch, sessionId }) => {
  const preFetch = useQuery(SESSION_DATA_MODULE_NAME);

  const getData = (): SESSION_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as SESSION_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const data = getData();
  const [modalVisible, setModalVisible] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setModalVisible(false);
  };

  const history = useHistory();

  const handleImportWebinar = () => {
    history.push(
      WEBINAR_ITEM_IMPORT_PAGE_PATH_DYNAMIC(
        data?.webinar.id,
        `/?person=true&sessionId=${sessionId}`,
      ),
    );
  };

  const handleImportSession = () => {
    history.push(
      SESSION_ITEM_IMPORT_PAGE_PATH_DYNAMIC(
        data?.webinar.id,
        sessionId,
        '/?person=true',
      ),
    );
  };

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  useOnClickOutside(ref, handleClickOutside);
  return (
    <Container spacing={4}>
      <SortSelect sort={sort} setSort={setSort} />

      <Search search={search} setSearch={setSearch} />

      <ModalContainer onClick={handleOpenModal}>
        <ButtonElemStyled
          color="backgroundThird"
          tid="PERSON.FILTER.IMPORT.BUTTON"
          iconRight={Icon}
        />
        {modalVisible && (
          <ModalElem ref={ref}>
            <ModalItem>
              <TextElem tid="PERSON.FILTER.IMPORT.EXCEL" />
            </ModalItem>
            <DividerElem />

            <ModalItem onClick={handleImportWebinar}>
              <TextElem tid="PERSON.FILTER.IMPORT.WEB" />
            </ModalItem>
            <DividerElem />

            <ModalItem onClick={handleImportSession}>
              <TextElem tid="PERSON.FILTER.IMPORT.SESSION" />
            </ModalItem>
          </ModalElem>
        )}
      </ModalContainer>
    </Container>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  > div {
    align-items: center;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  height: 46px;
  display: flex;
  cursor: pointer;
`;

const ModalItem = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  align-items: center;
  height: 48px;
  border-radius: 0;
  span {
    height: 16px;
  }

  :first-child {
    border-radius: 9px 9px 0 0;
  }

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  }
`;
const ModalElem = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  top: 54px;
  right: 0;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  position: absolute;

  border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  --height: auto;
  --box-shadow: none !important;
  --backdrop-opacity: 0;

  div:first-child {
    border-radius: 9px 9px 0 0;
  }

  div:last-child {
    border-radius: 0 0 9px 9px;
  }
`;

const Container = styled(GridElem)`
  grid-template-columns: 182px auto 182px;
  @media screen and (width<630px) {
    grid-template-columns: 1fr;
  }
  .button {
    @media screen and (width<630px) {
      max-width: 100%;
    }
  }

  align-content: start;
`;
