import {
  VIEWER_ITEM_LIST_DATA_RAW,
  VIEWER_ITEM_LIST_DATA,
} from '../../data/viewer/constant';

export const MODULE_NAME = 'VIEWER_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (sessionId: string, params?: string) =>
      `/viewer/list/${sessionId}?${params}`,
  },
  GET_ONLINE: {
    TYPE: 'GET',
    URL: (sessionId: string) => `/viewer/getOnlineViewer/${sessionId}`,
  },
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export interface ACTION_RESPONSE_INTER extends VIEWER_ITEM_LIST_DATA_RAW {}

export interface DATA extends VIEWER_ITEM_LIST_DATA {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
