import React from 'react';

import styled from 'styled-components';

import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';
import { FlexElem } from '../../common/flex';
import { SelectDynamicContainer } from '../select-dynamic';
import { getProjectList } from '../../data/project/action';
import { convertProjectListToSelect } from '../../data/project/convert';
import { ContentContainerElem } from '../../common/content-container';
import { Spacing } from '../../theme';
import { getWebinarList } from '../../data/webinar/action';
import { convertWebinarListToSelect } from '../../data/webinar/convert';
import { getSessionList } from '../../data/session/action';
import { convertSessionListToSelect } from '../../data/session/convert';
import { FieldTimeInputElem } from '../../common/field-time-input';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { FORM_VALUE_ENUM } from './constant';
import { FormikValues } from 'formik';

export const Component: React.FC<{
  data?: VIEWER_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
}> = ({
  data,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
}) => {
  console.log(formik.values);
  return (
    <Wrapper>
      <GridElem spacing={4}>
        <FiltertContainer spacing={3}>
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
            error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
            action={getProjectList}
            convert={convertProjectListToSelect}
            // dynamic
            name={FORM_VALUE_ENUM.PROJECT}
            isMulti
          />
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
            error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
            name={FORM_VALUE_ENUM.WEBINAR}
            action={getWebinarList}
            convert={convertWebinarListToSelect}
            dynamic
            isMulti
          />
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
            error={isFieldError(FORM_VALUE_ENUM.SESSION)}
            name={FORM_VALUE_ENUM.SESSION}
            action={getSessionList}
            convert={convertSessionListToSelect}
            dynamic
            isMulti
            isDisabled={!getFieldValue(FORM_VALUE_ENUM.WEBINAR)?.length}
            param={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
          />
          <FieldTimeInputElem />
        </FiltertContainer>
        <ContentContainerElem>
          <GridElem style={{ textAlign: 'center' }}>
            <TextElem type="semi-bold" size="label" tid="ANALYTICS.EMPTY.1" />
            <TextElem
              type="regular"
              size="small"
              tid="ANALYTICS.EMPTY.2"
              color="textPrimary"
            />
          </GridElem>
        </ContentContainerElem>
      </GridElem>
    </Wrapper>
  );
};

const FiltertContainer = styled(ContentContainerElem)`
  padding: ${Spacing(5)};
  display: flex;
  > {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  max-width: 828px;
  width: 828px;
  min-width: 828px;
  height: 100%;
`;
