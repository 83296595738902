import { FILE_ITEM_DATA_RAW } from '../file/constant';
import { PROJECT_ITEM_DATA_RAW } from '../project/constant';
import { SESSION_ITEM_DATA_RAW } from '../session/constant';
import { USER_DATA } from '../user/constant';
import { WEBGIFT_ITEM_DATA_RAW } from '../webgift/constant';

export interface WEBINAR_ITEM_DATA_RAW {
  id: string;
  createDate: string;
  name: string;
  speaker: USER_DATA;
  session: SESSION_ITEM_DATA_RAW[];
  webgift: WEBGIFT_ITEM_DATA_RAW[];
  project: PROJECT_ITEM_DATA_RAW;
  logo: FILE_ITEM_DATA_RAW;
  preview: FILE_ITEM_DATA_RAW;
  video: FILE_ITEM_DATA_RAW;
  speakerImg: FILE_ITEM_DATA_RAW;
  speakerName: string;
  topics: string[];
  bonuses: string[];
  guarantes: string[];
  price: number;
  buttonLinkDescription: string;
  buttonLinkName: string;
  buttonLink: string;
  plannedCount: number;
  comingNowCount: number;
  completedCount: number;
  autoCount: number;
  sessionCounter: number;
  youtubeLink?: string;
  moderMessage?: string;
  pixelId?: string;
  sessionCount: number;
  crmLink?: string;
  crmStatus?: string;
  crmPipeline?: string;
  crmFormName?: string;
  crmEventName?: string;
}

export interface WEBINAR_ITEM_LIST_DATA_RAW {
  list: WEBINAR_ITEM_DATA_RAW[];
}

export interface WEBINAR_ITEM_DATA extends WEBINAR_ITEM_DATA_RAW {
  plannedCount: number;
  comingNowCount: number;
  completedCount: number;
  autoCount: number;
  sessionCount: number;
}

export interface WEBINAR_ITEM_LIST_DATA {
  list: WEBINAR_ITEM_DATA[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/webinar/list',
  },
};
