import React from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import startIcon from '../../asset/svg/event/start.svg';
import { action } from './action';

export const Container: React.FC<{
  eventId: string;
}> = ({ eventId }) => {
  const actionMutate = useMutation(() => action(eventId), {});

  const isLoading = () => {
    if (actionMutate.isLoading) {
      return true;
    }
  };

  const actionHandleClick = (e: any) => {
    actionMutate.mutate();
  };

  const isError = () => {
    if (actionMutate.isError && !actionMutate.isLoading && getErrorMessage())
      return true;
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = actionMutate.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };
  return (
    <>
      {isLoading() && <LoaderElem />}

      <ButtonElem
        iconRight={startIcon}
        fill="clear"
        disabled={isLoading()}
        iconSize={24}
        onClick={actionHandleClick}
      />
      {isError() && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};
