import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';

export const Elem: React.FC<{
  tabList: TAB_ELEM_TYPE[];
  value: string;
  onChange: Function;
  name?: string;
  backgroundColor?: COLOR_TYPE;
  className?: string;
}> = ({
  tabList,
  value,
  onChange,
  name,
  backgroundColor = 'backgroundPrimary',
  className,
}) => {
  const handleChange = (value: string) => {
    if (name) {
      const e = {
        target: {
          name,
          value,
        },
      };

      if (onChange) {
        onChange(e);
      }
    } else {
      if (onChange) {
        onChange(value);
      }
    }
  };
  return (
    <Container backgroundColor={backgroundColor} className={className}>
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          active={item.value === value}
          onClick={() => handleChange(item.value)}
        >
          <TextElem
            tid={item.tid}
            color={item.value === value ? 'default' : 'textThird'}
            type="medium"
            oneLine
          />
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: COLOR_TYPE }>`
  padding: ${Spacing(0)} ${Spacing(1)};
  height: 46px;
  display: flex;
  align-items: center;

  background: ${(props) => props.theme[props.backgroundColor]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  gap: ${Spacing(1)};
  overflow-y: auto;
  scrollbar-width: none;

  @media screen and (width<800px) {
    ::after {
      content: '';
      display: block;
      width: 124px;
      height: 46px;
      background: ${({ theme }) =>
        `linear-gradient(90deg, ${theme[COLOR_ENUM.BACKGROUND_PRIMARY]}00, ${
          theme[COLOR_ENUM.BACKGROUND_PRIMARY]
        }FF)`};
      border-radius: ${SIZE_BORDER_RADIUS_DATA[
        SIZE_BORDER_RADIUS_ENUM.DEFAULT
      ]}px;
      right: 20px;
      position: absolute;
      pointer-events: none;
    }
  }
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  padding: 0 ${Spacing(5)};
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

  transition: all 0.2s;
  span {
    transition: all 0.2s;
  }

  :hover {
    span {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }

  ${({ active }) =>
    active
      ? css`
          :hover {
            border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
            span {
              color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
            }
          }
          background-color: ${({ theme }) =>
            theme[COLOR_ENUM.BACKGROUND_THIRD]};
        `
      : ''};
`;
