import React, { useState } from 'react';

import { ACTION_ERROR_INTER } from './constant';

import { Component } from './component';
import {
  maxLength,
  name,
  number,
  numberPositiveMin,
  required,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateProject } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { useHistory } from 'react-router';
import { SESSION_LIST_MODULE_NAME } from '../session-list';
import { convert } from '../session-item-create/convert';
import moment from 'moment';
import {
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from '../session-item-create/constant';

export const Container: React.FC<{
  data: SESSION_ITEM_DATA;
  close: Function;
}> = ({ data, close }) => {
  const query = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    query.invalidateQueries(SESSION_LIST_MODULE_NAME);
    formik.resetForm();
    close();
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => updateProject(data.id, values),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, name, maxLength(80)],
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.ONLINE_MAX]: [required, number, numberPositiveMin(0)],
    [FORM_VALUE_ENUM.ONLINE_MIN]: [required, number, numberPositiveMin(0)],
    [FORM_VALUE_ENUM.TIMEZONE]: [required],
    [FORM_VALUE_ENUM.AUTO]: [],
    [FORM_VALUE_ENUM.DATE_CONVERT]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: data.name,
    [FORM_VALUE_ENUM.DATE]: moment
      .utc(data.date)
      .format(data.auto ? 'HH:mm:ss.SSSS' : 'YYYY-MM-DDTHH:mm:ss.SSSS'),
    [FORM_VALUE_ENUM.ONLINE_MAX]: Number(data.onlineUserMax),
    [FORM_VALUE_ENUM.ONLINE_MIN]: Number(data.onlineUserMin),
    [FORM_VALUE_ENUM.TIMEZONE]: data.timeZone,
    [FORM_VALUE_ENUM.AUTO]: data.auto,
    [FORM_VALUE_ENUM.DATE_CONVERT]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      close={close}
      setFieldValue={setFieldValue}
      setValue={setValue}
    />
  );
};
