import React from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { convertViewer } from '../../data/viewer/convert';

import { action } from './action';
import {
  VIEWER_ITEM_DATA,
  VIEWER_ITEM_DATA_RAW,
} from '../../data/viewer/constant';
import { required } from '../../lib/validation/service';
import { FORM_VALUE_RAW } from '../event-item-create/constant';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';

export const Container: React.FC<{
  projectId: string;
  webinarId: string;
  sessionId: string;
}> = ({ projectId, webinarId, sessionId }) => {
  const preFetch = useQuery(MODULE_NAME, () => action(projectId), {
    enabled: false,
  });

  const isLoading = () => {
    if (preFetch.isLoading || preFetch.isFetching) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess && !preFetch.isFetching) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getData = (): VIEWER_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as VIEWER_ITEM_DATA_RAW;
    if (data) {
      return convertViewer(data);
    }
  };

  const config = {
    [FORM_VALUE_ENUM.PROJECT]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.PROJECT]: [],
    [FORM_VALUE_ENUM.WEBINAR]: [],
    [FORM_VALUE_ENUM.SESSION]: [],
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      // return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  return (
    <Component
      data={getData()}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      formik={formik}
    />
  );
};
