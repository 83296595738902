import { useEffect } from 'react';
import { useSocket } from '../../data/socket/provider';
import {
  EVENT_ITEM_DATA_RAW,
  QUESTION_TYPE,
  REACTION_RAW_TYPE,
  RESERVATION_CREATED_TYPE,
  SURVEY_RAW_TYPE,
  SURVEY_TYPE,
} from '../../data/event/constant';
import {
  MESSAGE_ITEM_DATA,
  MESSAGE_ITEM_DATA_RAW,
} from '../../data/message/constant';
import { BASE_URL } from '../../lib/http/constant';
import { useQueryClient } from 'react-query';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';
import { convertSurvey } from '../../data/event/convert';
import {
  convertMessageListWithCheck,
  convertMessageWithCheck,
} from '../../data/message/convert';

interface useRoomSocketProps {
  sessionToken: string;
  sessionId: string;
  setChatMessage: React.Dispatch<React.SetStateAction<MESSAGE_ITEM_DATA[]>>;
  setSessionStart: React.Dispatch<React.SetStateAction<boolean>>;
  setEventStarted: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionEnd: React.Dispatch<React.SetStateAction<boolean>>;
  setChatActive: React.Dispatch<React.SetStateAction<boolean>>;

  setEvent: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setSurvey: React.Dispatch<React.SetStateAction<SURVEY_TYPE | undefined>>;
  setBanner: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setButton: React.Dispatch<
    React.SetStateAction<EVENT_ITEM_DATA_RAW | undefined>
  >;
  setReactions: React.Dispatch<
    React.SetStateAction<REACTION_RAW_TYPE | undefined>
  >;

  setReservationCreated: React.Dispatch<
    React.SetStateAction<RESERVATION_CREATED_TYPE | undefined>
  >;
  setQuestion: React.Dispatch<React.SetStateAction<QUESTION_TYPE | undefined>>;
  viewerBan: () => void;
}

const useRoomSocket = ({
  sessionToken,
  sessionId,
  setChatMessage,
  setSessionStart,
  setEventStarted,
  setSessionEnd,
  setEvent,

  setBanner,
  setButton,
  setReactions,
  setReservationCreated,
  setQuestion,
  setChatActive,
  setSurvey,
  viewerBan,
}: useRoomSocketProps) => {
  const {
    socket,
    initializeSocket,
    sendMessage,
    sendReaction,
    reservationButtonClick,
    reservationCreated,
    sendAnswer,
  } = useSocket();

  const query = useQueryClient();

  useEffect(() => {
    if (sessionToken) {
      const cleanUpSocket = initializeSocket(BASE_URL as string, {
        auth: {
          session_token: sessionToken,
        },
      });
      const handleBeforeUnload = () => {
        if (socket) {
          socket.emit('leaveSession', `${sessionId}`);
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
        if (socket) {
          socket.emit('leaveSession', `${sessionId}`);
        }
        cleanUpSocket;
      };
    }
  }, [sessionToken]);

  useEffect(() => {
    if (socket) {
      socket.emit('joinSession', `${sessionId}`);

      socket.on('addPropViewerId', (viewerId: string) => {
        console.log('addPropViewerId');
        (socket as any).viewerId = viewerId;
      });

      socket.on('sessionMessages', (messages: MESSAGE_ITEM_DATA_RAW[]) => {
        console.log('sessionMessages', messages);
        setChatMessage(
          convertMessageListWithCheck(
            messages,
            messages[0].session.auto,
            (socket as any).viewerId,
          ),
        );
      });

      socket.on('chat', (message: MESSAGE_ITEM_DATA_RAW) => {
        console.log('chat', message);
        const newMessage = convertMessageWithCheck(
          message,
          message?.session?.auto,
          (socket as any).viewerId,
        );
        if (newMessage) {
          setChatMessage((previousMessages) => [
            ...previousMessages,
            newMessage,
          ]);
        }
      });

      socket.on('reactionUpdate', (reactionData: REACTION_RAW_TYPE) => {
        console.log(reactionData, 'getReactions');

        setReactions(reactionData);
      });

      socket.on('surveyUpdate', (survayData: SURVEY_RAW_TYPE) => {
        console.log(survayData, (socket as any).viewerId, 'surveyUpdate');

        setSurvey(
          survayData
            ? convertSurvey(survayData, (socket as any).viewerId)
            : undefined,
        );
      });

      socket.on('deleteMessageList', (idsToRemove: string[]) => {
        setChatMessage((previousMessages: MESSAGE_ITEM_DATA[]) => {
          return previousMessages.filter(
            (message) => !idsToRemove?.includes(message.id),
          );
        });
      });

      socket.on('paymentSuccess', () => {
        console.log('sessionStart');

        query.invalidateQueries(VIEWER_ITEM_DATA_MODULE_NAME);
      });

      socket.on('viewerBan', () => {
        console.log('viewerBan');
        viewerBan();
      });

      socket.on('sessionStart', () => {
        console.log('sessionStart');
        setSessionStart(true);
      });

      socket.on('chatActivated', () => {
        console.log('eventStart');
        setChatActive(true);
      });

      socket.on('chatInactive', () => {
        console.log('eventStart');
        setChatActive(false);
      });

      socket.on('eventStart', () => {
        console.log('eventStart');
        setEventStarted(true);
      });

      socket.on('sessionEnd', () => {
        setSessionEnd(true);
      });

      socket.on('showReservation', (event: EVENT_ITEM_DATA_RAW) => {
        console.log('showReservation');
        setEvent(event);
      });

      socket.on('showButton', (event: EVENT_ITEM_DATA_RAW) => {
        console.log('showButton', event);

        setButton(event);
      });

      socket.on('showBanner', (event: EVENT_ITEM_DATA_RAW) => {
        console.log('showBanner', event);
        setBanner(event);
      });

      socket.on('showQuestion', (data: QUESTION_TYPE) => {
        console.log('showQuestion', data);
        setQuestion(data);
      });

      socket.on('showReservationCreated', (data: RESERVATION_CREATED_TYPE) => {
        console.log('showReservationCreated', data);
        setReservationCreated(data);
        setTimeout(() => {
          if (data.name === reservationCreated.name)
            setReservationCreated(undefined);
        }, 3000);
      });

      socket.on('hideButton', () => {
        console.log('hideButton');
        setButton(undefined);
      });

      socket.on('hideBanner', () => {
        console.log('hideBanner');
        setBanner(undefined);
      });

      socket.on('closeReservation', () => {
        setEvent(undefined);
      });
    }
  }, [socket?.id]);

  return { sendMessage, reservationButtonClick, sendReaction, sendAnswer };
};

export default useRoomSocket;
