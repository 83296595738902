import React from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import {
  email,
  maxLength,
  name,
  required,
  userName,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery } from 'react-query';
import { updateWebinar } from './action';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { WEBINAR_ITEM_DATA_MODULE_NAME } from '../webinar-item-data';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { convert } from './convert';

export const Container: React.FC<{ webinarId: string }> = ({ webinarId }) => {
  const preFetch = useQuery([WEBINAR_ITEM_DATA_MODULE_NAME, webinarId], {
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
  const action = useMutation(
    (values: FORM_VALUE_INTER) => updateWebinar(webinarId, values),
    {},
  );

  const isLoadingPreFetch = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccessPreFetch = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const getData = (): WEBINAR_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as WEBINAR_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const data = getData();
  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, name, maxLength(80)],
    [FORM_VALUE_ENUM.USER]: [email],
    [FORM_VALUE_ENUM.PROJECT]: [required],
    // [FORM_VALUE_ENUM.LOGO]: [required],
    // [FORM_VALUE_ENUM.SPEAKER_IMG]: [required],
    [FORM_VALUE_ENUM.SPEAKER_NAME]: [required, userName],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues =
    isSuccessPreFetch() && data
      ? {
          [FORM_VALUE_ENUM.NAME]: data?.name,
          [FORM_VALUE_ENUM.USER]: data?.speaker?.email,
          [FORM_VALUE_ENUM.PROJECT]: data?.project?.id,
          [FORM_VALUE_ENUM.SPEAKER_NAME]: data?.speakerName,
          // [FORM_VALUE_ENUM.LOGO]: data?.logo?.id,
          // [FORM_VALUE_ENUM.SPEAKER_IMG]: data?.speakerImg?.id,
        }
      : {
          [FORM_VALUE_ENUM.NAME]: '',
          [FORM_VALUE_ENUM.USER]: '',
          [FORM_VALUE_ENUM.PROJECT]: '',
          [FORM_VALUE_ENUM.SPEAKER_NAME]: '',
          // [FORM_VALUE_ENUM.SPEAKER_IMG]: '',
          // [FORM_VALUE_ENUM.LOGO]: '',
        };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccessPreFetch={isSuccessPreFetch()}
      isLoadingPreFetch={isLoadingPreFetch()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      setValue={setValue}
      data={data}
    />
  );
};
