import React from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import {
  VIEWER_ITEM_DATA,
  VIEWER_ITEM_LIST_DATA,
} from '../../data/viewer/constant';
import { ViewerItemElem } from '../../data/viewer/frame/viewer-item-list.elem';
import { AlertEmptyElem } from '../../common/alert-empty';

import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import { IonInfiniteScroll } from '@ionic/react';
import { ViewerFilterContainer } from '../viewer-filter';

import { ViewerSkeletonElem } from '../../data/viewer/frame/viewer-skeleton.elem';

export const Component: React.FC<{
  data?: VIEWER_ITEM_LIST_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
  setValue: Function;
  value: string;
  search: string;
  setSearch: Function;
  setRange: Function;
  // setSession: Function;
  // session: any;
  sessionId: string;
  setOnline: Function;
  online: boolean;
  setDate: Function;
  date: any;
  range: string | null;
  hasNextPage?: boolean;
}> = ({
  data,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  setValue,
  value,
  search,
  setSearch,

  setRange,
  // session,
  // setSession,
  sessionId,
  setOnline,
  online,
  date,
  setDate,
  range,
  hasNextPage,
}) => {
  return (
    <GridElem spacing={5}>
      <ViewerFilterContainer
        value={value}
        setValue={setValue}
        // session={session}
        // setSession={setSession}
        search={search}
        setSearch={setSearch}
        setRange={setRange}
        sessionId={sessionId}
        online={online}
        setOnline={setOnline}
        setDate={setDate}
        date={date}
        range={range}
      />
      {isError && <AlertActionElem text={errorMessage} />}
      {data && data.isEmpty && !isFetching && <AlertEmptyElem />}
      <Container>
        <NewGrid>
          {data && !data.isEmpty && !isFetching && (
            <>
              <Head>
                <TextElem
                  tid="VIEWER.LIST.HEAD.NAME"
                  type="medium"
                  color="textPrimary"
                />
                <TextElem
                  tid="VIEWER.LIST.HEAD.NUMBER"
                  type="medium"
                  color="textPrimary"
                />

                <TextElem
                  tid="VIEWER.LIST.HEAD.WATCH_TIME"
                  type="medium"
                  color="textPrimary"
                  style={{ justifySelf: 'center' }}
                />

                <TextElem
                  tid="VIEWER.LIST.HEAD.BAN"
                  type="medium"
                  color="textPrimary"
                  style={{ justifySelf: 'center' }}
                />
                <TextElem
                  tid="VIEWER.LIST.HEAD.REQUEST"
                  type="medium"
                  color="textPrimary"
                  style={{ justifySelf: 'center' }}
                />
                <TextElem
                  tid="VIEWER.LIST.HEAD.STATUS"
                  type="medium"
                  color="textPrimary"
                  style={{ justifySelf: 'center' }}
                />
              </Head>
              {data?.list.map((item: VIEWER_ITEM_DATA) => (
                <ViewerItemElem {...item} key={item.id} />
              ))}
            </>
          )}
        </NewGrid>
        {isFetching && <ViewerSkeletonElem />}
      </Container>
      {hasNextPage && (
        <IonInfiniteScroll
          onIonInfinite={async (ev) => {
            await fetchNextPage();
            ev.target.complete();
          }}
          threshold="100px"
          disabled={!hasNextPage}
        >
          <div style={{ height: '1px' }}></div>
        </IonInfiniteScroll>
      )}
    </GridElem>
  );
};
const Container = styled.div`
  overflow-x: auto;
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
`;

const NewGrid = styled(GridElem)`
  grid-auto-columns: repeat(6, 'auto');
  row-gap: ${Spacing(3)};
  width: 1050px;
`;

const Head = styled(GridElem)`
  height: 46px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  width: 100%;
  align-items: center;
  padding: 0 ${Spacing(5)};
  display: grid;
  grid-column: span 6;
  grid-template-columns: subgrid;
  gap: ${Spacing(12)};
`;
