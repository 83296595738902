import React, { createContext, useContext, useState } from 'react';
import io, { Socket } from 'socket.io-client';

interface SocketContextType {
  socket: Socket | null;
  initializeSocket: (url: string, options?: object) => void;
  sendMessage: (content: string, replayId: string) => void;
  sendReaction: (emoji: string) => void;
  sendAnswer: (answer: number) => void;
  changeChatActive: () => void;
  reservationButtonClick: () => void;
  reservationCreated: () => void;
}

const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const initializeSocket = (url: string, options?: object) => {
    const newSocket = io(url, options);
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      setSocket(null);
    };
  };

  const sendMessage = (content: string, replyId: string) => {
    const message = { content, replyId };
    if (socket) {
      socket.emit('chat', message);
    }
  };

  const changeChatActive = () => {
    if (socket) {
      socket.emit('changeChatActive');
    }
  };

  const reservationButtonClick = () => {
    if (socket) {
      socket.emit('buttonIsOpen');
    }
  };

  const reservationCreated = () => {
    if (socket) {
      socket.emit('reservationCreated');
    }
  };

  const sendReaction = (emoji: string) => {
    if (socket) {
      socket.emit('sendReaction', { emoji });
    }
  };

  const sendAnswer = (answer: number) => {
    if (socket) {
      socket.emit('sendAnswer', { answer });
    }
  };

  const value = {
    socket,
    initializeSocket,
    sendMessage,
    changeChatActive,
    reservationButtonClick,
    sendReaction,
    reservationCreated,
    sendAnswer,
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};
