import React, { useState } from 'react';

import { FlexElem } from '../../common/flex';
import { AnalyticItemContainer } from '../analytic-item';
import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { ReactComponent as AddIcon } from '../../asset/svg/analytic/add.svg';

export const Container: React.FC<{
  projectId: string;
  webinarId: string;
  sessionId: string;
}> = ({ projectId, webinarId, sessionId }) => {
  const [analytics, setAnalytics] = useState<string[]>([
    new Date().toISOString(),
  ]);
  const addNewAnalytic = () =>
    setAnalytics([...analytics, new Date().toISOString()]);

  return (
    <Wrapper spacing={3}>
      {analytics.map((analytic, index) => (
        <AnalyticItemContainer
          projectId={''}
          webinarId={''}
          sessionId={''}
          key={analytic}
        />
      ))}
      <AddButton onClick={addNewAnalytic}>
        <AddIcon />
      </AddButton>
    </Wrapper>
  );
};

const Wrapper = styled(FlexElem)`
  overflow-x: scroll;
  scrollbar-width: none;
`;

const AddButton = styled.div`
  cursor: pointer;
  height: 86px;
  width: 86px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
