import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { useOnClickOutside } from 'usehooks-ts';
import { ContentContainerElem } from '../../../common/content-container';
import { DividerElem } from '../../../common/divider';
import { FlexElem } from '../../../common/flex';
import { GridElem } from '../../../common/grid';
import { EventItemDeleteContainer } from '../../../epic/event-item-delete';
import { EventItemUpdateContainer } from '../../../epic/event-item-update';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { ReactComponent as EditIcon } from '../../../asset/svg/common/edit.svg';
import { ReactComponent as StartIcon } from '../../../asset/svg/event/start.svg';
import { ReactComponent as StopIcon } from '../../../asset/svg/event/stop.svg';
import { ReactComponent as MoreIcon } from '../../../asset/svg/common/more.svg';
import { ReactComponent as DeleteIcon } from '../../../asset/svg/delete/delete.svg';
import { Message } from './message';
import { Reservation } from './reservation';
import { Banner } from './banner';
import { Button } from './button';
import { MessageMobile } from './messageMobile';
import { ReservationMobile } from './reservationMobile';
import { BannerMobile } from './bannerMobile';
import { ButtonMobile } from './buttonMobile';
import { Timecode } from './timecode';
import { TimecodeMobile } from './timecodeMobile';
import { GroupMessage } from './groupMessage';
import { GroupMessageMobile } from './groupMessageMobile';
import { ReactionMobile } from './reactionMobile';
import { Reaction } from './reaction';
import { Question } from './question';
import { QuestionMobile } from './questionMobile';
import { ReservationCreated } from './reservationCreated';
import { ReservationCreatedMobile } from './reservationCreatedMobile';
import { SurveyMobile } from './surveyMobile';
import { Survey } from './survey';
import { EventItemStopContainer } from '../../../epic/event-item-stop';
import { EventItemStartContainer } from '../../../epic/event-item-start';

const Elem: React.FC<{
  data: EVENT_ITEM_DATA;
  updateVisible: string | null;
  setUpdateVisible: Function;
}> = ({ data, setUpdateVisible, updateVisible }) => {
  const {
    id,
    message,
    time,
    person,
    type,
    buttonText,
    startTime,
    endTime,
    TypeIcon,
    session,
  } = data;

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setModalVisible(false);
  };

  const closeModalVisible = () => setModalVisible(false);

  const deleteModalOpen = () => {
    setDeleteModalVisible(true);
    setTimeout(() => {
      closeModalVisible();
    });
  };

  const deleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const handleUpdateVisible = (e: any) => {
    setModalVisible(false);
    setUpdateVisible(data.id);
  };

  const handleOpenModal = (e: any) => {
    e.stopPropagation();
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setUpdateVisible(null);
    setModalVisible(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);

  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible]);

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {width < 600 ? (
        <MobileContent updVisible={updateVisible === data.id} hover={false}>
          {updateVisible === data.id ? (
            <EventItemUpdateContainer
              data={data}
              sessionId={data.session.id}
              close={handleCloseModal}
            />
          ) : (
            <GridElem spacing={4}>
              <FlexElem
                style={{
                  justifyContent: 'space-between',
                  alignContent: 'center',
                }}
              >
                <FlexElem>
                  {data.session?.auto && (
                    <FlexElem>
                      <TextElem type="semi-bold" size="main" oneLine>
                        {time}
                      </TextElem>
                      <TypeIcon />
                    </FlexElem>
                  )}

                  <ModalContainer onClick={handleOpenModal}>
                    <MoreIconStyled />

                    {modalVisible && (
                      <ModalElem ref={ref}>
                        <ModalItem onClick={handleUpdateVisible}>
                          <EditIcon className="editIcon" />
                          <TextElem tid="SESSION.UPDATE.MODAL.EDIT" />
                        </ModalItem>

                        <DividerElem />
                        <ModalItem onClick={deleteModalOpen}>
                          <DeleteIcon
                            style={{ filter: 'none' }}
                            className="delete"
                          />
                          <TextElem
                            tid="COMMON.MODAL.BUTTON.DELETE"
                            color="error"
                          />
                        </ModalItem>
                      </ModalElem>
                    )}
                  </ModalContainer>
                </FlexElem>
              </FlexElem>
              <FlexElem spacing={5}>
                <GridElem spacing={3}>
                  {type == EVENT_TYPE.MESSAGE && <MessageMobile data={data} />}
                  {type == EVENT_TYPE.REACTION && (
                    <ReactionMobile data={data} />
                  )}

                  {type == EVENT_TYPE.RESERVATION && (
                    <ReservationMobile data={data} />
                  )}
                  {type == EVENT_TYPE.BANNER && <BannerMobile data={data} />}
                  {type == EVENT_TYPE.BUTTON && <ButtonMobile data={data} />}
                  {type == EVENT_TYPE.TIMECODE && (
                    <TimecodeMobile data={data} />
                  )}
                  {type == EVENT_TYPE.QUESTION && (
                    <QuestionMobile data={data} />
                  )}
                  {type == EVENT_TYPE.RESERVATION_CREATED && (
                    <ReservationCreatedMobile data={data} />
                  )}

                  {type == EVENT_TYPE.GROUP_MESSAGE && (
                    <GroupMessageMobile data={data} />
                  )}

                  {type == EVENT_TYPE.SURVEY && <SurveyMobile data={data} />}
                </GridElem>
              </FlexElem>
            </GridElem>
          )}

          <EventItemDeleteContainer
            eventId={id}
            open={deleteModalVisible}
            onClose={deleteModalClose}
          />
        </MobileContent>
      ) : (
        <>
          <Card updVisible={updateVisible === data.id} hover={false}>
            {updateVisible === data.id ? (
              <EventItemUpdateContainer
                data={data}
                sessionId={data.session.id}
                close={handleCloseModal}
              />
            ) : (
              <>
                {session?.auto && (
                  <EllipsisContainerElem>
                    <TextElem type="semi-bold" size="main" oneLine>
                      {time}
                    </TextElem>
                  </EllipsisContainerElem>
                )}

                <TypeIcon />
                {type == EVENT_TYPE.MESSAGE && <Message data={data} />}
                {type == EVENT_TYPE.REACTION && <Reaction data={data} />}

                {type == EVENT_TYPE.RESERVATION && <Reservation data={data} />}
                {type == EVENT_TYPE.BANNER && <Banner data={data} />}
                {type == EVENT_TYPE.BUTTON && <Button data={data} />}
                {type == EVENT_TYPE.TIMECODE && <Timecode data={data} />}
                {type == EVENT_TYPE.QUESTION && <Question data={data} />}
                {type == EVENT_TYPE.RESERVATION_CREATED && (
                  <ReservationCreated data={data} />
                )}
                {type == EVENT_TYPE.GROUP_MESSAGE && (
                  <GroupMessage data={data} />
                )}
                {type == EVENT_TYPE.SURVEY && <Survey data={data} />}

                {!session?.auto && (
                  <FlexElem spacing={2} style={{ paddingLeft: '12px' }}>
                    <EventItemStopContainer eventId={id} />
                    <EventItemStartContainer eventId={id} />
                  </FlexElem>
                )}

                <ModalContainer onClick={handleOpenModal}>
                  <MoreIconStyled />

                  {modalVisible && (
                    <ModalElem ref={ref}>
                      <ModalItem onClick={handleUpdateVisible}>
                        <EditIcon className="editIcon" />
                        <TextElem tid="SESSION.UPDATE.MODAL.EDIT" />
                      </ModalItem>
                      <DividerElem />

                      <DividerElem />
                      <ModalItem onClick={deleteModalOpen}>
                        <DeleteIcon
                          style={{ filter: 'none' }}
                          className="delete"
                        />
                        <TextElem
                          tid="COMMON.MODAL.BUTTON.DELETE"
                          color="error"
                        />
                      </ModalItem>
                    </ModalElem>
                  )}
                </ModalContainer>
              </>
            )}
          </Card>
          <EventItemDeleteContainer
            eventId={id}
            open={deleteModalVisible}
            onClose={deleteModalClose}
          />
        </>
      )}
    </>
  );
};

const MobileContent = styled(CardElem)<{
  updVisible: boolean;
}>`
  position: relative;
  cursor: auto;
  width: 100%;
  max-width: 100%;
  ${({ updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          border: solid 1px transparent;
        }
      `;
    }
  }}
  span {
    max-width: 100%;
    overflow: hidden;
    display: block;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const MoreIconStyled = styled(MoreIcon)`
  height: 24px;
  transition: all 0.2s;
  circle {
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    transition: all 0.2s;
  }
  :hover {
    circle {
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
    }
  }
`;

const Card = styled(CardElem)<{ updVisible: boolean }>`
  display: grid;
  cursor: auto;

  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  align-items: center;
  ${({ updVisible }) => {
    if (updVisible) {
      return css`
        grid-template-columns: 100%;
        grid-column: span 5;
      `;
    }
  }}
`;

const ModalContainer = styled.div`
  position: relative;
  height: 24px;
  display: flex;
  cursor: pointer;
  margin-left: ${Spacing(4)};
`;

const ModalItem = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  align-items: center;
  height: 48px;
  border-radius: 0;
  span {
    height: 16px;
  }
  svg {
    path {
      transition: all 0.1s;
    }
  }
  :first-child {
    border-radius: 9px 9px 0 0;
  }

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};

    svg {
      circle {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
    }
    .editIcon {
      path {
        fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
        stroke: transparent;
      }
    }
    svg.delete {
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
      }
    }
  }
`;
const ModalElem = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  top: 30px;
  right: 0;
  width: 180px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  position: absolute;

  border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  width: 180px;
  --height: auto;
  --box-shadow: none !important;
  --backdrop-opacity: 0;

  div:first-child {
    border-radius: 9px 9px 0 0;
  }

  div:last-child {
    border-radius: 0 0 9px 9px;
  }
`;

export { Elem as EventItemElem };
